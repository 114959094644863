:root {
  /* Main theme color */
  --portal-theme-primary: rgba(9, 35, 41, 1);
  --portal-theme-secondary: rgba(22, 52, 60, 1);
  --portal-theme-light: rgba(14, 54, 65, 0.53);
  --portal-theme-color: #2a9988;
  --text-primary-color: #90a7ad;
  --text-white-color: #fff;
  --input-border-color: rgb(255 255 255 / 36%);
}

body {
  background-color: var(--portal-theme-primary);
  color: var(--text-primary-color) !important;
  font-family: "Parkinsans", sans-serif;
  font-weight: normal;
  font-size: 14px !important;
}

body::-webkit-scrollbar,
.mui-table-container::-webkit-scrollbar {
  display: none;
}

.mui-table-container {
  min-width: auto !important;
}

.app-header,
.app-sidebar {
  background-color: var(--portal-theme-secondary) !important;
  color: var(--text-primary-color);
}

.app-header {
  flex-direction: row !important;
  justify-content: space-between;
}

.app-sidebar-box,
.app-header,
.app-sidebar-box .MuiPaper-elevation {
  transition: all 0.5s ease !important;
}

.sidebar-permanent-drawer {
  transition: all 0.5s ease !important;
}

.account-menu-box .MuiPopover-paper {
  position: absolute;
  top: 50px !important;
  right: 25px !important;
  left: auto !important;
}

.account-popover-avatar-img {
  width: 40px !important;
  height: 40px !important;
  top: 10px;
  right: 20px;
}

.account-menu-box .MuiPopover-paper::before,
.custom-menu-popover .MuiPopover-paper::before {
  background-color: var(--portal-theme-primary) !important;
}

.account-menu-box {
  color: var(--text-primary-color) !important;
}

.account-menu-box ul svg {
  color: var(--portal-theme-color);
}

.user-profile-name {
  padding: 5px 16px;
}

.account-menu-box ul {
  /* padding: 16px; */
  color: var(--text-primary-color) !important;
}

.account-menu-box ul li {
  padding-top: 8px;
  padding-bottom: 8px;
}

.account-menu-box ul li:hover,
.custom-menu-popover ul li:hover {
  background-color: var(--portal-theme-secondary);
}

.app-logo {
  width: 50px;
}

.app-logo-name {
  color: var(--text-primary-color);
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  margin-left: 12px;
  font-size: 18px;
  margin-top: 5px;
}

.menus-list {
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--text-primary-color) !important;
  width: 100%;
  padding-left: 16px !important;
  font-size: 14px !important;
}

.menus-list span {
  font-size: 14px !important;
}

.menus-list:hover {
  background-color: var(--portal-theme-light) !important;
}

.menuActive {
  background-color: var(--portal-theme-secondary) !important;
  color: var(--portal-theme-color) !important;
}

.menus-list svg {
  color: var(--portal-theme-color) !important;
}

.theme-card {
  background-color: var(--portal-theme-secondary) !important;
  border-radius: 5px;
}

.mui-bg-custom-table {
  background-color: transparent !important;
  box-shadow: none !important;
}

.mui-bg-custom-table tr {
  white-space: nowrap;
}

.sidebar-divider {
  color: #fff;
}

.sidebar-icons {
  font-size: 18px;
  width: 35px;
}

.dashboard-icon-box {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 auto;
  background-color: var(--portal-theme-color);
  padding-top: 6px;
  font-size: 14px;
  margin-bottom: 16px;
  color: #fff;
}

.MuiTableCell-head,
.MuiTableSortLabel-root,
.MuiTableSortLabel-root svg {
  color: var(--portal-theme-color) !important;
  font-weight: bold !important;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif !important; */
}

.MuiTableCell-head,
.MuiTableCell-body {
  padding: 12px !important;
  border: none !important;
  color: var(--text-primary-color) !important;
}

.MuiTableRow-root:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.MuiTableCell-head {
  border-bottom: 1px solid var(--input-border-color) !important;
}

.mui-table-footer {
  display: flex !important;
  justify-content: space-between;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}

.MuiTablePagination-select:focus {
  background-color: transparent !important;
}

.MuiTablePagination-selectIcon,
.MuiTablePagination-toolbar,
.MuiTablePagination-actions svg {
  color: var(--text-primary-color) !important;
  fill: var(--text-primary-color) !important;
}

.MuiMenu-paper,
.MuiMenu-list {
  background-color: var(--portal-theme-primary) !important;
  color: var(--portal-theme-color) !important;
}

.MuiMenu-paper .Mui-selected {
  background-color: var(--portal-theme-secondary) !important;
  color: var(--portal-theme-color);
}

.reduce-cell-padding {
  padding: 5px !important;
}

.mui-table-search-input {
  color: var(--text-primary-color) !important;
  border: 1px solid var(--input-border-color) !important;
}

.mui-table-search-input input {
  padding: 10px 0px;
}

.Mui-focused fieldset {
  outline: none !important;
  border: none !important;
}

.login-form .Mui-focused fieldset,
.login-form fieldset {
  border: 1px solid var(--input-border-color) !important;
}

.mui-table-heading h2 {
  font-size: 25px;
}

.add--button button {
  border: none;
  background-color: var(--portal-theme-color);
  padding: 7px 25px !important;
  color: #fff !important;
  border-radius: 3px;
  font-size: 15px;
}

.forms-drawer-height {
  height: 100%;
  overflow: auto;
  padding-bottom: 40px;
}

/* width */
.app-sidebar-box .MuiDrawer-paper::-webkit-scrollbar,
.chatting-users-list::-webkit-scrollbar,
.chatting-messages::-webkit-scrollbar,
.forms-drawer-height::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
.app-sidebar-box .MuiDrawer-paper::-webkit-scrollbar-track,
.chatting-users-list::-webkit-scrollbar-track,
.chatting-messages::-webkit-scrollbar-track,
.forms-drawer-height::-webkit-scrollbar-track {
  background: var(--portal-theme-primary);
}

/* Handle */
.app-sidebar-box .MuiDrawer-paper::-webkit-scrollbar-thumb,
.chatting-users-list::-webkit-scrollbar-thumb,
.chatting-messages::-webkit-scrollbar-thumb,
.forms-drawer-height::-webkit-scrollbar-thumb {
  background: rgba(50, 68, 73, 0.603);
}

/* Handle on hover */
.app-sidebar-box .MuiDrawer-paper::-webkit-scrollbar-thumb:hover,
.chatting-users-list::-webkit-scrollbar-thumb:hover,
.chatting-messages::-webkit-scrollbar-thumb:hover,
.forms-drawer-height::-webkit-scrollbar-thumb:hover {
  background: var(--portal-theme-light);
}

.app-sidebar-box .MuiDrawer-paper {
  z-index: 0 !important;
}

.forms-drawer .MuiDrawer-paper {
  background-color: var(--portal-theme-primary) !important;
  width: 600px;
  max-width: 100%;
}

.forms-drawer .drawer-title {
  color: var(--text-primary-color);
}

.drawer-cross-icon {
  background-color: var(--portal-theme-secondary) !important;
  color: var(--text-primary-color) !important;
}

.input-form .form-control {
  background-color: transparent !important;
  color: var(--text-primary-color) !important;
  border: 1px solid var(--portal-theme-secondary);
}

.input-form .form-control .Mui-disabled {
  -webkit-text-fill-color: var(--text-primary-color);
}

.input-form .form-control label {
  color: var(--text-primary-color) !important;
}

.input-form .form-control input,
.input-form .MuiOutlinedInput-root,
.input-form .MuiOutlinedInput-root:hover {
  color: var(--text-primary-color);
  border: none !important;
}

.input-form .mui-select,
.MuiSelect-select fieldset {
  padding: 0;
  color: var(--text-primary-color) !important;
}

.input-form .mui-select:focus {
  border: 1px solid var(--portal-theme-secondary) !important;
}

.mui-select svg {
  color: var(--portal-theme-color);
}

.upload-button label {
  align-items: center;
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  height: 76px;
  padding: 15px;
  width: 76px;
  border: 1px solid var(--portal-theme-color);
}

.upload-file-preview img {
  border-radius: 5px;
  display: inline;
  height: 76px;
  margin-right: 10px;
  width: 76px;
}

.upload-file-preview span {
  background-color: red;
  border-radius: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  position: absolute;
  right: 3px;
  text-align: center;
  top: -10px;
  width: 20px;
  cursor: pointer;
}

.upload-file-preview {
  position: relative;
}

.light-border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.login-card {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.login-card .login-logo img {
  width: 80px;
}

.login-card .theme-card {
  max-width: 480px;
  min-width: 311px;
  width: 100%;
  text-align: center;
}

.login-form .add--button button {
  width: 100%;
  font-size: 16px;
  padding: 8px 0;
}

.login-form .visibility-password svg {
  color: var(--portal-theme-color) !important;
}

.login-card .developed-by {
  text-align: center;
  padding: 1.5rem;
  color: var(--portal-theme-color);
}

.login-button-box span {
  width: 20px !important;
  height: 20px !important;
  /* margin-top: 4px; */
  color: #fff !important;
}

.snackbar-cross-icon svg {
  color: #fff;
  font-size: 16px;
}

.circular-progress {
  height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.circular-progress svg {
  color: var(--portal-theme-color) !important;
}

.mui-confirmation-popup .MuiPaper-elevation,
.mui-component-popup .MuiPaper-elevation {
  background-color: var(--portal-theme-primary);
}

.mui-confirmation-popup p,
.mui-confirmation-popup button {
  color: var(--portal-theme-color) !important;
}

.mui-component-popup .MuiPaper-elevation {
  padding: 30px;
}

.mui-component-popup-title {
  color: var(--portal-theme-color);
}

.change-my-password {
  width: 380px;
  max-width: 100%;
  text-align: center;
}

.change-my-password h3 {
  color: var(--portal-theme-color);
}

/* .change-my-password button {
    color: #fff !important;
} */

.visibility-password svg {
  color: var(--portal-theme-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiPickersPopper-paper {
  background-color: var(--portal-theme-secondary) !important;
  color: #fff !important;
}

.MuiPickersPopper-paper svg,
.mui-date-picker svg {
  color: var(--portal-theme-color) !important;
}

.MuiPickersPopper-paper .Mui-selected {
  background-color: var(--portal-theme-color) !important;
  color: #fff !important;
}

.MuiDayPicker-weekDayLabel {
  color: #fff !important;
}

.MuiPickersDay-root {
  background-color: var(--portal-theme-secondary) !important;
  color: #fff !important;
}

.MuiPickersDay-root:hover {
  background-color: var(--portal-theme-color) !important;
  color: #fff !important;
}

.MuiPickersDay-today {
  border: 1px solid var(--portal-theme-color) !important;
  color: var(--portal-theme-color) !important;
}

/* .MuiClock-amButton {
    background-color: var(--portal-theme-color) !important;
    color: #fff !important;
    font-size: 17px !important;
} */

.autocomplete-paper {
  background-color: var(--portal-theme-secondary) !important;
  color: #fff !important;
}

.autocomplete-mui .MuiAutocomplete-tag,
.autocomplete-mui .MuiAutocomplete-tag svg {
  background-color: var(--portal-theme-secondary);
  color: var(--portal-theme-color);
}

.child-menus-list {
  padding-left: 40px !important;
}

.lesson-book-card {
  height: 250px;
  display: flex;
  border: 2px dotted var(--portal-theme-color);
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.lesson-book-card a,
.lesson-card-link a,
.holy-quran-index-pages a {
  text-decoration: none;
}

.lesson-book-card h2,
.lesson-card-link h2 {
  color: var(--portal-theme-color);
  cursor: pointer;
  margin-bottom: 0;
}

.lesson-card-link {
  height: 120px;
  display: flex;
  border: 2px dotted var(--text-primary-color);
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
}

.lesson-card-link h2 {
  font-size: 22px;
}

.holy-quran-index-pages h2 {
  font-size: 20px;
  margin-top: 10px;
  color: var(--portal-theme-color);
}

.lesson-main-page-number h2 {
  font-size: 30px;
}

.arrows-on-main-page a {
  color: var(--portal-theme-color);
}

.back-button-title {
  display: flex;
}

.back-button-box {
  height: 38px;
  width: 38px;
  background-color: #194f4740;
  text-align: center;
  border-radius: 50%;
  padding-top: 6px;
  margin-right: 20px;
  /* opacity: 0.5; */
}

.back-button-box a {
  color: var(--portal-theme-color);
  font-size: 18px;
}

.back-button-title .table-title {
  color: var(--portal-theme-color);
  font-size: 18px;
  margin-top: 7px;
}

.data-not-found-box {
  border-bottom: 1px solid var(--input-border-color) !important;
}

.default-checkbox {
  color: var(--portal-theme-color) !important;
}
.progress-checkbox {
  color: var(--portal-theme-secondary) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.make-link span {
  color: var(--portal-theme-color);
  cursor: pointer;
}
.make-link span:hover {
  text-decoration: underline;
}
.remaining-time {
  font-size: 61px;
  letter-spacing: 1px;
  color: #3eed3e;
  font-family: monospace;
  justify-content: center;
}

.remaining-time-text {
  color: #fff;
  font-size: 27px;
}

.filter-button-on-report button {
  white-space: nowrap;
  margin-left: 10px;
  margin-top: 3px;
}

.filter-button-on-report .MuiInputBase-sizeSmall,
.filter-button-on-report .MuiInputBase-sizeSmall:hover {
  border: 1px solid var(--input-border-color) !important;
}

.filter-button-on-report svg {
  color: var(--portal-theme-color);
  fill: var(--portal-theme-color);
}

.student-group {
  padding: 5px 20px;
  border: 1px solid var(--portal-theme-color);
  color: var(--text-primary-color);
  border-radius: 15px;
  margin: 0 2px;
  cursor: pointer;
}

.student-group.active-student {
  background-color: var(--portal-theme-color);
  color: #fff;
  cursor: auto;
}

.chatting-sidebar .chatting-user .user-name h5 {
  margin-left: 5px;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 0;
}

.chatting-sidebar .chatting-user .user-name p {
  margin-left: 5px;
  font-size: 14px;
  margin-bottom: 0;
}

.chatting-sidebar .message-date {
  margin-top: 5px;
  font-size: 11px;
  padding-top: 2px;
}

.chatting-section {
  height: 80vh;
  border: 1px solid var(--portal-theme-secondary);
  position: relative;
}

.chatting-sidebar .chatting-user {
  cursor: pointer;
}

.chatting-sidebar .search-bar {
  color: var(--portal-theme-color);
}

.chatting-sidebar .search-bar label {
  color: var(--portal-theme-color);
}

.chatting-sidebar .search-bar input,
.chatting-sidebar .search-bar svg {
  color: var(--portal-theme-color);
}

.chatting-sidebar .search-bar fieldset {
  border: 1px solid var(--portal-theme-color) !important;
}

.css-1mj6pcd-MuiAvatar-root {
  width: 40px !important;
  height: 40px !important;
}

.chatting-users-list {
  height: 72vh;
  overflow: auto;
}

.chatting-section .user-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.write-message {
  padding: 5px;
  position: absolute;
  bottom: 0;
}

.write-message .message-input-box {
  background-color: var(--portal-theme-secondary);
  color: var(--text-primary-color);
  width: 100%;
  border-radius: 25px;
}

.write-message input {
  background-color: var(--portal-theme-secondary);
  color: var(--text-primary-color);
  width: 94%;
  padding: 8px 0px;
  padding-left: 15px;
  border-radius: 25px;
  outline: none;
  border: none;
}

.message-image-preview-box {
  padding: 10px;
  border-bottom: 1px solid;
}

.message-image-preview-box .image-preview {
  position: relative;
  height: 82px;
  width: 82px;
  border: 1px solid var(--input-border-color);
  border-radius: 10px;
}

.message-image-preview-box img {
  border-radius: 10px;
  height: 80px;
  width: 80px;
}

.cross-image-preview {
  position: absolute;
  height: 20px;
  width: 20px;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: red;
  font-size: 14px;
  font-weight: bold;
  right: 2px;
  top: 2px;
  cursor: pointer;
}

.last-message-box {
  font-size: 14px;
  margin-top: 2px;
}

.last-message-box svg {
  font-size: 20px;
  margin-right: 2px;
  margin-top: -3px;
}

.chatting-section .user-card {
  background-color: var(--portal-theme-secondary);
  padding: 10px;
  height: 60px;
}

.chatting-messages {
  padding: 6px 12px;
  height: calc(72vh);
  overflow: auto;
}

.chatting-messages .message {
  position: relative;
}

.chatting-messages .message .message-date span {
  font-size: 11px;
  background-color: transparent;
  padding-left: 0;
}

.chatting-messages .message .message-body {
  background-color: var(--portal-theme-secondary);
  padding: 5px 8px;
  border-radius: 3px;
  max-width: 90%;
  text-align: left;
}

.chatting-messages .message .message-body img {
  width: 100%;
}

.chatting-messages .calling-message .text-chat-message span {
  padding: 2px 20px;
  background-color: #d8d8d8;
  color: var(--portal-theme-light);
  border-radius: 15px;
  font-size: 12px;
}

.outgoing-message {
  text-align: right;
}

.calling-message {
  text-align: center;
}

.add-new-chat .user-name p {
  color: var(--text-primary-color);
  margin-bottom: 0px;
  margin-left: 10px;
  margin-top: 7px;
}

.add-new-chat .chat-border {
  border: 1px solid var(--portal-theme-color);
  padding: 7px;
  border-radius: 25px;
  cursor: pointer;
}

.no-messages {
  height: 52vh;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
}

.circular-progress-message {
  height: 60vh;
}

.unread_message_count {
  background: var(--portal-theme-color);
  color: #fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: auto;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-button-action {
  height: 38px;
  width: 38px;
  background-color: #41655f40;
  text-align: center;
  border-radius: 50%;
  padding-top: 4px;
  margin-right: 20px;
  color: var(--portal-theme-color);
  padding-top: 9px;
  position: relative;
  cursor: pointer;
}

.header-button-action span {
  position: absolute;
  top: -5px;
  color: #fff;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 10px;
  background-color: rgb(255, 72, 66);
  right: -5px;
}

.header-button-action i {
  font-size: 18px;
}

.chat_popover_menu .MuiMenu-list,
.chat_popover_menu .MuiPopover-paper::before {
  /* background-color: var(--portal-theme-primary) !important; */
  background-color: var(--portal-theme-secondary) !important;
  overflow: hidden;
}

.personal-notes-card {
  border-radius: 5px;
  border: 1px solid;
}

.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.no-access-string p {
  margin-bottom: 0 !important;
}

.mui-slider {
  color: var(--portal-theme-color) !important;
}

.mui-slider .MuiSlider-markLabel {
  color: var(--text-primary-color) !important;
}
.mui-slider .MuiSlider-valueLabelOpen {
  background-color: var(--portal-theme-primary) !important;
}

.mui-slider .MuiSlider-markLabel[data-index="0"] {
  left: 17px !important;
  right: auto;
}

.mui-slider .MuiSlider-markLabel[data-index="1"] {
  left: auto !important;
  right: -23px;
}

.draggable-box {
  position: absolute;
  width: 200px;
  height: 160px;
  background-color: var(--portal-theme-color);
  border: 1px solid var(--input-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  z-index: 1;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.draggable-box img,
.image-logo-box img {
  width: 70%;
}

.draggable-box .image-box {
  padding: 15px;
  text-align: center;
}

.draggable-box .icon-box {
  height: 50px;
  width: 100%;
  background-color: var(--portal-theme-primary);
  display: flex;
  justify-content: space-around;
  z-index: 1;
}

.start-call-box {
  height: 35px;
  width: 35px;
  border: 1px solid var(--portal-theme-color);
  text-align: center;
  border-radius: 50%;
  padding-top: 6px;
  cursor: pointer;
  background-color: var(--portal-theme-color);
}
.success-call-box {
  height: 35px;
  width: 35px;
  border: 1px solid var(--input-border-color);
  text-align: center;
  border-radius: 50%;
  padding-top: 6px;
  cursor: pointer;
  background-color: var(--portal-theme-color);
  margin-left: 5px;
  margin-top: 5px;
}

.success-call-box.error {
  background-color: red;
}

.success-call-box.call-accepting {
  opacity: 0.4;
}

.start-call-box svg {
  fill: var(--text-primary-color);
  color: var(--text-primary-color);
}

.action-icons-box {
  display: flex;
  justify-content: space-between;
}

.meeting-wrapper {
  position: relative;
  height: 110px;
}

.meeting-top-section {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.draggable-box .meeting-top-section {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  padding: 5px 0;
  align-items: center;
}

.call-timer-box {
  border: 1px solid;
  padding: 0px 10px;
  border-radius: 15px;
}

.meeting-top-section .change-call-view {
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  background-color: var(--portal-theme-light);
  border-radius: 50%;
  padding-top: 3px;
  z-index: 1;
}

.call-timer-section {
  z-index: 1;
}

.full-call-box {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: var(--portal-theme-color);
  border: 1px solid var(--input-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex-direction: column;
  border-radius: 5px;
}
.full-call-box img {
  width: 60%;
}

.full-call-box .image-box {
  text-align: center;
}

.full-call-box .icon-box {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 30px;
}

.full-call-box .meeting-wrapper {
  width: 100%;
  height: 100%;
}

.full-call-box .meeting-wrapper video {
  width: 100%;
  height: 90vh !important;
  padding: 0;
}

.circle-box {
  height: 30px !important;
  width: 30px !important;
  background-color: var(--portal-theme-color);
  border-radius: 50%;
  border: 1px solid var(--input-border-color);
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.circle-box img,
.circle-box video {
  display: none;
}

.circle-box .change-call-view {
  text-align: center;
  padding-top: 2px;
}

.my-video-box {
  position: absolute;
  bottom: 50px;
  right: 10px;
  border-radius: 5px;
}

.full-call-box .my-video-box > div {
  height: 100px !important;
  width: 200px !important;
}

@media only screen and (max-width: 571px) {
  .full-call-box .my-video-box {
    bottom: 100px;
  }
}

.todo-priority {
  padding: 3px 30px;
  border-radius: 15px;
  color: var(--text-white-color);
  text-transform: capitalize;
  text-align: center;
}

.todo-priority.normal {
  background-color: #007bff;
}

.todo-priority.low {
  background-color: #e3c15a;
}

.todo-priority.high {
  background-color: #e91c30;
}

.todo-priority.least {
  background-color: #6c757d;
}

.add-class-day svg {
  color: var(--portal-theme-color);
  fill: var(--portal-theme-color);
}

.icon-remove-day {
  color: #c62828;
  fill: #c62828;
  margin-top: 16px;
  cursor: pointer;
}

.choose-image-container {
  border: 1px solid var(--portal-theme-secondary);
  display: flex;
  justify-content: space-between;
  height: 55px;
  border-radius: 4px;
  position: relative;
}

.choose-image-label {
  position: absolute;
  top: -11px;
  left: 15px;
  font-size: 13px;
  color: var(--text-primary-color);
}

.image-preview-box {
  /* border-radius: 50%; */
  border: 1px solid var(--portal-theme-color);
  background-color: #fff;
}

.image-preview-box img {
  height: 50px;
  width: 57px;
  /* border-radius: 50%; */
  object-fit: cover;
}

.choose-file-button {
  background-color: var(--portal-theme-color);
  color: #fff;
  cursor: pointer;
  padding: 6px 25px;
  margin-left: 13px;
  border-radius: 2px;
  border: 1px solid #fff;
}

.choose-file-box {
  margin-top: 8px;
}

.fees-info-list {
  padding-left: 0;
  margin-bottom: 0 !important;
}

.fee-status {
  padding: 3px 20px;
  border-radius: 15px;
  background-color: green;
  color: var(--text-white-color);
}

.content-editable-box {
  border: none;
  color: var(--text-primary-color);
  overflow: hidden;
  transition: height 1s ease;
  position: relative;
  flex: 1;
}

.content-editable {
  outline: none;
  overflow: auto;
  height: 20px;
}

.content-editable:empty:before {
  content: attr(data-placeholder);
  pointer-events: none;
}

.input-editor-box {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.message-send-icon {
  width: 38px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--portal-theme-color);
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
}

.message-send-icon label {
  cursor: pointer;
}

.message-send-icon svg {
  color: #fff;
  fill: #fff;
  font-size: 23px;
}

.chat-formatters-box {
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: none;
  height: 0;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: height 0.5s ease;
  background-color: var(--portal-theme-primary);
}

.chat-formatters-box.formatter-border {
  border: 1px solid var(--input-border-color);
}

.open-formatter-input {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  transition: all 0.5s ease;
}

.show-hide-chat-formatter {
  position: absolute;
  right: 10px;
  color: var(--text-primary-color);
  cursor: pointer;
  bottom: 10px;
}

.formatter-icon {
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  margin-right: 5px;
  padding-top: 3px;
  text-align: center;
  width: 30px;
}

.message-box-view a {
  color: var(--portal-theme-color);
}

.recording-slider {
  width: 100%;
  background-color: var(--portal-theme-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  height: 37px;
}

.recording-slider .inner-slider {
  width: 84%;
  height: 7px;
  background-color: var(--portal-theme-primary);
}

.recorded-audio {
  width: 10%;
  height: 100%;
  background-color: var(--portal-theme-color);
}

.recorded-time {
  padding-left: 10px;
  color: var(--text-secondary-color);
  width: 53px;
}

.stop-recording {
  cursor: pointer;
}

.stop-recording svg {
  margin-right: 5px;
  font-size: 20px;
}

.react-audio-player {
  background-color: var(--portal-theme-secondary);
  padding: 7px 15px 7px 10px;
  border-radius: 2px;
}

.custom-audio-player svg {
  color: var(--portal-theme-color);
  fill: var(--portal-theme-color);
  cursor: pointer;
}

.custom-audio-player {
  display: flex;
  align-items: center;
}

.custom-audio-player .played-audio {
  height: 100%;
  max-width: 100% !important;
  background-color: var(--portal-theme-color);
  border-radius: 3px;
}

.custom-audio-player .full-player {
  width: 100px;
  height: 3px;
  background-color: #000;
  margin-left: 5px;
  border-radius: 3px;
}

.audio-loader {
  height: 16px !important;
  width: 16px !important;
  margin-top: 5px;
}

.image-logo-box {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: var(--portal-theme-color);
}

.is-disconnecting-call {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--portal-theme-light);
  z-index: 1111;
}

.disconnecting-text {
  width: 700px;
  max-width: 100%;
  text-align: center;
  font-weight: bold;
  color: #fff;
}

.custom-card-element {
  padding: 12px;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
}

.pay-now-button {
  padding: 2px 10px;
  border: 2px solid var(--portal-theme-color);
  color: var(--portal-theme-color);
  font-weight: 600;
  border-radius: 20px;
  cursor: pointer;
}
